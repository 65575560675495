import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import swal from 'sweetalert';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import BootstrapButton from '../../common/Buttons'
import useStyles from '../../common/FormStyle';
import Copyright from '../../components/Copyright';
import loginUser from '../../Services/auth/AuthService';

const theme = createTheme();

export default function Signin() {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const handleSubmit = async e => {
        e.preventDefault();
        const response = await loginUser({
            email,
            password
        });
        const accessStorage = {
            'accessToken': response.token,
            'id': response.id,
            'firstName': response.firstName,
            'lastName': response.lastName,
            'role': response.role
        }

        if (response.token) {
            swal("Zalogowano pomyślnie", " ", "success", {
                buttons: false,
                timer: 1000,
            })
                .then((value) => {
                    localStorage.setItem('accessStorage', JSON.stringify(accessStorage))
                    localStorage.setItem('searchQuery', '"0"')
                   
                    
                        window.location.href = "/dashboard";
                    
                    
                });
        } else {
            swal("Błąd logowania", " ", "error");
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                ></Box>
                <Grid>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Zaloguj się
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                name="email"
                                label="Email Address"
                                onChange={e => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                onChange={e => setPassword(e.target.value)}
                            />
                            <BootstrapButton sx={{ mt: 2, mb: 0 }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Zaloguj się
                            </BootstrapButton>
                        </form>
                    </div>
                </Grid>
                <Copyright sx={{ mt: 2, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}




