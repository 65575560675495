import React from 'react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { PrivateRouteVisible } from '../../../routes';
import hasPermission from '../../../Services/auth/CheckPermission';

export default function RenderCategories(props) {
    const history = useNavigate();
    const [open, setOpen] = React.useState({});
    const { data } = props;

    const handleOpen = (id, state) => {
        setOpen({ [id]: state });
    }
    return (
        <>
            <PrivateRouteVisible auth={{ isAuth: hasPermission(data.permissions) }}>
                <ListItemButton
                    onClick={() => {
                        (data?.childCategory !== undefined) ?
                            handleOpen(data.id, !open[data.id]) :
                            history(data.url);
                    }}
                    sx={{
                        py: '6px',
                        color: '#fff',
                        background: (window.location.pathname === data.url) ? '#ffffff29' : '',
                        '&:hover, &:focus': { '& svg': { opacity: 1 } },
                    }}
                >
                    {data.icon}
                        <ListItemText
                            primary={data.name}
                            primaryTypographyProps={{
                                fontSize: 14,
                                fontWeight: 'medium',
                                lineHeight: '20px',
                                mb: '2px',
                            }}
                            secondary={(data.description) ? data.description : ''}
                            secondaryTypographyProps={{
                                noWrap: true,
                                fontSize: 12,
                                lineHeight: '16px',
                                display: open[data.id] ? 'none' : 'block',
                                color: open[data.id] ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                            }}
                        />
                        {(data?.childCategory !== undefined) &&

                        <KeyboardArrowDown
                            sx={{
                                mr: -1,
                                opacity: 0,
                                transform: open[data.id] ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '0.2s',
                            }}
                        />}
                </ListItemButton>
            </ PrivateRouteVisible>
            {
                (data?.childCategory !== undefined) &&
                open[data.id] && (
                    data?.childCategory.map((child) => (
                        <PrivateRouteVisible key={child.id} auth={{ isAuth: hasPermission(data.permissions) }}>
                            <Box className='collapseMenu'
                                sx={{
                                    overflow: 'auto',
                                    opacity: '1',
                                    transition: 'all .3s',
                                    height: 'calc(100% - 160px)'
                                }} >
                                <ListItemButton
                                    key={child.id}
                                    onClick={() => history(child.url)}
                                    sx={{
                                        py: 0,
                                        minHeight: 32,
                                        color: '#ffffffbf',
                                        background: (window.location.pathname === child.url) ? '#ffffff29' : '',
                                    }}
                                ><ListItemIcon >
                                        <FiberManualRecordIcon sx={{ opacity: '0' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={child.name}
                                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                                    />
                                </ListItemButton>
                                {(child?.component !== undefined) && (
                                    child?.component
                                )}
                            </Box>
                        </PrivateRouteVisible>
                    ))
                )
            }
        </>
    )
}
