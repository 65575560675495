import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from '../components/dashboard/DashboardNavigator';
import Header from '../components/dashboard/DashboardHeader';
import Copyright from '../components/Copyright';
import theme from '../common/DashboardStyle';

let mobile;
export function hideMobileMenu(offState) {
  mobile(!offState)
}

const DashboardLayout = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  mobile = setMobileOpen
  let drawerWidth = 247;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth, transition: 'all .2s ease-in' } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}
          <Navigator
            PaperProps={{ style: { width: drawerWidth, transition: 'all .2s ease-in' } }}
            sx={{ display: { sm: 'block', xs: 'none' } }}
          />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header
            onDrawerToggle={handleDrawerToggle}
          />
          <Box sx={{ height: '57px' }}>
          </Box>
          <Box component="main" sx={{ flex: 1, py: { sm: 2, xs: 2 }, pt: 5, px: { sm: 2, xs: 1 }, bgcolor: '#eaeff1', overflow: 'auto' }}>
            <Outlet />
          </Box>
          <Box component="footer" sx={{ px: 2, pb: 2, bgcolor: '#eaeff1' }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default DashboardLayout;  