import React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FireNav from '../../common/NavStyle';
import { useNavigate } from 'react-router-dom';
import {
  appVersion,
  bottomMenu,
  mainMenu
} from '../../config/mainMenu.config'
import RenderCategories from './menu/renderCategories'

export default function Navigator(props) {
  const history = useNavigate();
  const { setdesktop, ...other } = props;

  return (
    <Drawer
      variant="permanent"
      {...other} >

      <ThemeProvider theme={createTheme({
        components: {
          MuiListItemButton: {
            defaultProps: {
              disableTouchRipple: true,
            },
          },
        },
        palette: {
          mode: 'dark',
          primary: { main: 'rgba(255,255,255,1)' },
          background: { paper: '#1e1f21' },
        },
      })}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: "space-between",
          flexDirection: "column",
          height: '100%'
        }}>
          <Paper elevation={0}
            sx={{ maxWidth: 256 }}>
            <FireNav component="nav" disablePadding sx={{ transition: 'opactiy .3s ease-in' }}>

              <ListItemButton sx={{
                pt: "30px",
                pb: "30px",
                height: '80px',

              }}
                onClick={() => { history(`/dashboard`) }}
              >

                {/* <Fade in={mainMenu} unmountOnExit> */}
                  <img src="/logo-gk.svg"
                    width='150px'
                    alt="Logo"
                  ></img>
                {/* </Fade> */}

              </ListItemButton>

              {mainMenu.map((data) => (
                <RenderCategories data={data} key={data.id} />
              ))}

            </FireNav>
          </Paper>

          <FireNav component="nav" disablePadding sx={{ transition: 'opactiy .3s ease-in' }}>
            <Box
              sx={{
                width: '100%',
              }}>
              <Divider />
              {bottomMenu.map((data) => (
                <RenderCategories data={data} key={data.id} />
              ))}
              <Divider />
              <Box
                sx={{
                  color: 'rgba(255,255,255,.2)',
                  fontSize: '.8rem',
                  float: 'right',
                  py: 1,
                  px: 1
                }}>
                {appVersion.name} {appVersion.version}
              </Box>
            </Box>
          </FireNav>
        </Box>
      </ThemeProvider>
    </Drawer >

  );
}
