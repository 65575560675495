import { styled } from "@mui/material";
import { InputBase } from '@mui/material';


const Search = styled('div')(() => ({
    position: 'relative',
    backgroundColor: '#000',
    '&:hover': {
        backgroundColor: '#000',
    },
    '&:focus':{
        backgroundColor: '#00580f40',
    }
}));

const SearchIconWrapper = styled('div')(() => ({
    padding: '0 16px 0 24px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        paddingLeft: `calc(1em + ${theme.spacing(5.5)})`,
        fontSize: '.9rem',
        color: '#fff',
        
    },
}));


export {
    Search,
    SearchIconWrapper,
    StyledInputBase
}