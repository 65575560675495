import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
// import { useHistory } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import jwt from 'jwt-decode'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useEffect, useState } from 'react';
import { token, host } from '../../Services/Connnection';
import { getInitials } from '../../common/get-initials';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';


export default function Profile() {
  const history = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("accessStorage");
    localStorage.removeItem("searchQuery");
    window.location.href = "/";
  };

  const tokenDecode = jwt(localStorage.accessStorage);
  const currentDate = Math.round(+new Date() / 1000);

  if (currentDate > tokenDecode['exp']) {
    localStorage.removeItem("accessStorage");
    localStorage.removeItem("searchQuery");
    window.location.href = "/";
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    let retrievedObject = localStorage.getItem('accessStorage')
    let storageData = JSON.parse(retrievedObject)
    

    fetch(`${host}/api/User/${storageData.id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'applcation/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data)
      });

  };
  useEffect(() => {
    fetchData();
  }, []);

  return (

    <Box>

      <Grid sx={{ flexWrap: "nowrap" }} container spacing={1} alignItems="center">
        <Grid item>
          <Link
            variant="body2"
            sx={{
              textDecoration: 'none',
              color: '#000',
              fontSize: '.8rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                color: '#000',
              },
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            Cześć, <strong>{user.firstName}</strong>
          </Link>
        </Grid>
        <Grid item>
          <IconButton onClick={handleClick} color="inherit" sx={{ p: 0.5 }}>
            <Avatar
              src={user.profilePicture}
              aria-describedby={id}
              variant="contained"
              sx={{ width: 24, height: 24, fontSize: '.7rem' }}
            >
              {getInitials(user.firstName)}
            </Avatar>
          </IconButton>
        </Grid>

      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <nav aria-label="main mailbox folders">
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => history(`/account`)}>
                  <ListItemIcon>
                    <AccountCircleRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Moje konto" />
                </ListItemButton>
              </ListItem>

              <Divider />

              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Wyloguj" />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      </Popover>
    </Box>
  );
}

