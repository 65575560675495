const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex', 
    flexDirection: 'column',
    maxHeight: 'calc(100% - 50px)',
    overflowY: 'auto',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 50px)',
    maxWidth: 1100,
    bgcolor: '#f7f7f7',
    boxShadow: 6,
};

export {
    style
}