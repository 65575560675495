export default {
    Administrator: {
        'Permissions.Settings.View': true,
        'Permissions.Settings.Users.View': true,
        'Permissions.Settings.Users.List.Edit': true,
        'Permissions.Settings.Users.List.View': true,
        'Permissions.Settings.Users.Add.Edit': true,
        'Permissions.Settings.ProductHunter.View': true,
        'Permissions.Settings.ProductHunter.Website.Edit': true,
        // 'Permissions.Account.View': true,
        'Permissions.Account.Edit': true,
        'Permissions.ProdScanner.View': true,
        'Permissions.Products.View': true,
        'Permissions.Products.Info.View': true,
        'Permissions.Products.Info.Edit': true,
        'Permissions.Products.Category.View': true,
        'Permissions.Products.Category.Edit': true,
        'Permissions.Products.Dimensions.View': true,
        'Permissions.Products.Dimensions.Edit': true,
        'Permissions.Products.Import.View': true,
        'Permissions.Products.Import.Edit': true,
        'Permissions.ProdSets.View': true,
        'Permissions.Dashboard.View': true,
        'Permissions.Dashboard.ProductHunter.View': true,
        'Permissions.Dashboard.Bundle.View': true,
        'Permissions.Dashboard.Warehouse.View': true,
        'Permissions.Warehouse.View': true,
        'Permissions.Warehouse.Edit': true,
        'Permissions.Integration.View': true,
        'Permissions.Integration.Edit': true,
        'Permissions.Integration.PrincipleCeneo.View': true,
        'Permissions.Integration.PrincipleCeneo.Edit': true,

        // Advanced products
        'Permissions.AdvancedProducts.List.View': true,
        'Permissions.AdvancedProducts.Product.View': true,
        'Permissions.AdvancedProducts.Product.Edit': true,
        'Permissions.AdvancedProducts.Suppliers.View': true,
        'Permissions.AdvancedProducts.Suppliers.Edit': true,
        'Permissions.AdvancedProducts.Suppliers.TradeTerm.View': true,
        'Permissions.AdvancedProducts.Suppliers.TradeTerm.Edit': true,

    },
    Synchronizator: {
        'Permissions.Settings.View': true,
        'Permissions.Settings.Users.List.View': true,
        'Permissions.Settings.Users.List.Edit': false,
        'Permissions.Settings.Users.Add.Edit': false,
        // 'Permissions.Account.View': false,
        'Permissions.Account.Edit': true,
        'Permissions.ProdScanner.View': true,
        'Permissions.Dashboard.View': true,
        'Permissions.Dashboard.ProductHunter.View': true,
        'Permissions.Dashboard.Bundle.View': true,
        'Permissions.Dashboard.Warehouse.View': true,
    },
    Magazynier: {
        'Permissions.Dashboard.View': true,
        'Permissions.Warehouse.View': true,
        'Permissions.Warehouse.Edit': true,
        'Permissions.Account.Edit': true,
        'Permissions.Dashboard.Warehouse.View': true
    }
}