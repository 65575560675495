import React from 'react'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useParams } from 'react-router';
import { WebsitesDictionaryList } from '../../../Pages/ProductHunter/Models';

const MenuWebsitesList = ({ data, nav }: { data: WebsitesDictionaryList, nav: (id: number) => void }) => {
  const { id, websiteName, visited } = data;
  const { id: websiteId = '0' } = useParams<{ id?: string }>();

  return (
    <ListItemButton
      key={id}
      onClick={() => { nav(id) }}
      sx={{
        py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)',
        ...(parseFloat(websiteId) === id && { background: 'rgba(255, 255, 255, 0.08)' })
      }}
    >
      <ListItemIcon >
        <FiberManualRecordIcon sx={{
          color: "#fff", transform: 'scale(.5)',
          ...(visited === false && { opacity: '1', color: '#4BB543', }),
          ...(visited === true && { opacity: '0' })
        }} />
      </ListItemIcon>
      <ListItemText
        primary={websiteName}
        primaryTypographyProps={{
          fontSize: 14,
          ...(parseFloat(websiteId) === id && { fontWeight: 'bold' })
        }}
      />
    </ListItemButton>
  )
}
export {
  MenuWebsitesList
}
