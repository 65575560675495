import React from 'react'
import CategoryIcon from '@mui/icons-material/Category';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import StraightenIcon from '@mui/icons-material/Straighten';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import Home from '@mui/icons-material/Home';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import MenuWebsitesIndex from '../shared/components/ProductHunterMenu/MenuWebsitesIndex.tsx'

const mainMenu = [
  {
    'id': 1,
    'name': 'Strona główna',
    'url': '/dashboard',
    'icon': <Home sx={{ mr: 1, color: '#818082' }} />,
    'permissions': 'Permissions.Dashboard.View'
  },
  {
    'id': 2,
    'name': 'Skaner produktów',
    'icon': <TravelExploreIcon sx={{ mr: 1, color: '#818082' }} />,
    'url': '',
    'permissions': 'Permissions.Settings.ProductHunter.View',
    'childCategory': [
      {
        'id': 21,
        'name': 'Wszystkie produkty',
        'url': '/hunter/list',
        'component': <MenuWebsitesIndex />,
        'permissions': 'Permissions.Settings.ProductHunter.View',

      },
    ]
  },
  {
    'id': 3,
    'name': 'Wymiarowanie produktów',
    'url': '/warehouse',
    'icon': <StraightenIcon sx={{ mr: 1, color: '#818082' }} />,
    'permissions': 'Permissions.Warehouse.View'
  },
  {
    'id': 4,
    'name': 'Produkty',
    'url': '',
    'icon': <CategoryIcon sx={{ mr: 1, color: '#818082' }} />,
    'permissions': 'Permissions.AdvancedProducts.List.View',
    'childCategory': [
      {
        'id': 41,
        'name': 'Lista produktów',
        'url': '/products/list',
        'permissions': 'Permissions.AdvancedProducts.List.View'
      },
      {
        'id': 42,
        'name': 'Importuj produkt',
        'url': '/products/import',
        'permissions': 'Permissions.Products.Import.View'
      },
    ]
  },
  {
    'id': 5,
    'name': 'Zestawy produktów',
    'url': '/product-sets',
    'icon': <WorkspacesIcon sx={{ mr: 1, color: '#818082' }} />,
    'permissions': 'Permissions.ProdSets.View'
  },
  {
    'id': 6,
    'name': 'Dostawcy',
    'url': '',
    'icon': <LocalShippingIcon sx={{ mr: 1, color: '#818082' }} />,
    'permissions': 'Permissions.AdvancedProducts.Suppliers.View',
    'childCategory': [
      {
        'id': 61,
        'name': 'Lista dostawców',
        'url': '/suppliers-list',
        'permissions': 'Permissions.AdvancedProducts.Suppliers.View'
      },
      {
        'id': 62,
        'name': 'Standardowe grupy rabatowe',
        'url': '/products/settings/tradeTerm',
        'permissions': 'Permissions.AdvancedProducts.Suppliers.TradeTerm.View'
      },
      {
        'id': 63,
        'name': 'Zaawansowane grupy rabatowe',
        'url': '/advanced-discounting',
        'permissions': 'Permissions.AdvancedProducts.Suppliers.TradeTerm.View'
      },
    ]
  },
  {
    'id': 7,
    'name': 'Integracje',
    'url': '/integration/list',
    'icon': <IntegrationInstructionsIcon sx={{ mr: 1, color: '#818082' }} />,
    'permissions': 'Permissions.Integration.View',
  },
]


const bottomMenu: IDashBoardMenu = [
  {
    'id': 7,
    'name': 'Ustawienia',
    'description': 'Rozwiń, aby zobaczyć więcej',
    'icon': <SettingsIcon sx={{ marginRight: 1, color: '#818082' }} />,
    'url': '',
    'permissions': 'Permissions.Settings.View',
    'childCategory': [
      {
        'id': 71,
        'name': 'Użytkownicy',
        'url': '/settings/users',
        'permissions': 'Permissions.Settings.Users.View'
      }
    ]
  }
]



const appVersion =
{
  'name': 'ver. Alpha',
  'shortName': 'ver.',
  'version': '1.6'
}


export {
  appVersion,
  bottomMenu,
  mainMenu
}