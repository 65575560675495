import getBundleList from '../../Services/productBundles/productBundlesService';
import { totalCountFunc } from '../../components/productBundles/productBundlesList'

export default async function renderData(searchValue, size, pageNumber, fetch) {
    let getBundleProd = await getBundleList(searchValue, size, pageNumber, fetch);
    var actualProd = [];

    let productItem = getBundleProd.items
    let totalCount = getBundleProd.totalCount
    totalCountFunc(totalCount)
    productItem.forEach(prod => {
        let row = {};
        row.brand = prod.brand
        row.bundleItems = prod.bundleItems
        row.creationTime = prod.creationTime
        row.creatorId = prod.creatorId
        row.id = prod.id
        row.calculatedStock = prod.calculatedStock
        row.isActive = prod.isActive
        row.lastModificationTime = prod.lastModificationTime
        row.lastModifierId = prod.lastModifierId
        row.name = prod.name
        row.symbol = prod.symbol
        actualProd.push(row);
    });

    return actualProd;
}