import { ProductHunterApiService } from '../ApiServices/ApiService'
import { ProductsList, WebsiteId, WebsiteDictionary, WebsitesDictionaryList, LastViewItems, ProcessedState, Product, Filters, ProductsPostParams, ActionType, } from '../../Models'
import { ProcessedData } from '../../Models/ProcessedState';
import { GridFilterItem, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { IDataService } from './IDataService';


export class ProductHunterDataService implements IDataService {
    apiService: any;
    static idsArray = [];
    static ProcessedState: any;

    constructor() {
        this.apiService = new ProductHunterApiService();
    }

    async GetLastViewItem(): Promise<LastViewItems> {
        let LastView = await this.apiService.GetLastView();
        return LastView?.lastViews as LastViewItems
    }

    async GetWebsiteDictionary(): Promise<WebsiteDictionary> {
        let webistes = await this.apiService.GetWebsiteDictionary();
        return webistes?.websites
    }

    async WebsiteDictionaryObject(): Promise<WebsiteDictionary> {
        let websites = await this.GetWebsiteDictionary()
        let lastViewDate = await this.GetLastViewItem()
        websites.forEach((d: WebsitesDictionaryList) => {
            d.visited = !this.compareDates(d.id, d.latestProductDate, lastViewDate)
        })
        return websites as WebsiteDictionary
    }

    compareDates(id: number, LatestProductDate: Date, lastViewDate: LastViewItems) {
        let lastUpdate = lastViewDate.find(e => e.websiteId === id);
        if (lastUpdate === undefined) {
            return true
        }
        else if (LatestProductDate > lastUpdate?.lastDateView) {
            return true

        } else {
            return false
        }
    }

    getWebSiteId(): number {
        let url = window.location.pathname;
        let id: number = parseFloat(url.substring(url.lastIndexOf('/') + 1));
        if (isNaN(id)) return 0
        return id as number
    }

    putCurrentDate(): Promise<WebsiteId> {
        let id = this.getWebSiteId()
        let websiteID = { "websiteId": id }
        return this.apiService.PutLastView(websiteID)
    }

    capitalizeFirstLetter(str: string) {
        return str?.charAt(0).toUpperCase() + str?.slice(1)
    }

    orderBy(SortModel: GridSortModel): string {
        return this.capitalizeFirstLetter(SortModel?.[0]?.['field'])
    }

    orderDescending(SortModel: GridSortModel): boolean {
        return (SortModel?.[0]?.['sort'] === 'asc') ? false : true
    }

    translateOperator(operator: string | undefined): string {
        switch (operator) {
            case 'contains':
                return 'Contain'
            case 'equals':
                return 'Exact'
        }
        return 'Contain'
    }

    filterObject(filterValue: GridFilterModel): Filters[] {
        let filters: Filters[] = [];
        if (filterValue) {
            (filterValue.items?.length > 0) &&
                filterValue.items.forEach((data: GridFilterItem) => {
                    let object: Filters = {
                        column: this.capitalizeFirstLetter(data.columnField),
                        filter: data.value,
                        filterType: this.translateOperator(data.operatorValue)
                    };
                    filters.push(object)
                })
        }
        return filters
    }

    exportToCsv(data: string): void {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', 'productHunter.csv');
        a.click()
    }

    paginatedSortedListParams(
        filter: GridFilterModel,
        SortModel: GridSortModel,
        PageNumber: number,
        PageSize: number,
        Type: string,
    ): Promise<ProductsList> {
        let websiteId = this.getWebSiteId();
        let query: ProductsPostParams = {
            websiteId: websiteId,
            name: '',
            symbol: '',
            eanSymbol: '',
            exactSearch: true,
            filters: this.filterObject(filter),
            orderBy: (this.orderBy(SortModel)) ? this.orderBy(SortModel) : 'DateAdded',
            orderDescending: this.orderDescending(SortModel),
            pageNumber: PageNumber || 1,
            pageSize: PageSize || 50
        }
        if (websiteId === 0) delete query?.websiteId

        let actionType: ActionType = { "type": undefined };

        if (Type === 'export')
            actionType.type = this.ExportOnlineProducts(query)
        if (Type === 'search')
            actionType.type = this.PaginatedSortedListItems(query)

        return actionType.type
    }

    async PaginatedSortedListItems(_params: ProductsPostParams): Promise<ProductsList> {
        let product = await this.apiService.GetProducts(_params)
        return product
    }

    async ExportOnlineProducts(_params: ProductsPostParams): Promise<void> {
        let product = await this.apiService.ExportOnlineProducts(_params)
        return this.exportToCsv(product)
    }

    getAllSelectedIds(ids: never[]) {
        ProductHunterDataService.idsArray = ids
    }

    async setProcessedState(processedData: ProcessedData, action: string): Promise<ProcessedState> {
        let processedDataArray = [] as ProcessedData;
        switch (action) {
            case 'select':
                ProductHunterDataService.idsArray.forEach((productId: number) => {
                    let row = {
                        id: productId,
                        processed: true
                    };
                    processedDataArray.push(row)
                });
                ProductHunterDataService.ProcessedState = {
                    "processedData": processedDataArray
                }
                break;
            case 'unselect':
                ProductHunterDataService.idsArray.forEach((productId: number) => {
                    let row = {
                        id: productId,
                        processed: false
                    };
                    processedDataArray.push(row)
                });
                ProductHunterDataService.ProcessedState = {
                    "processedData": processedDataArray
                }
                break;
            case '':
                ProductHunterDataService.ProcessedState = {
                    "processedData": [
                        processedData
                    ]
                }
                break;
        };
        return await this.apiService.SetProcessedState(ProductHunterDataService.ProcessedState);
    }

    async HistoricalData(_id: any): Promise<Product> {
        let HistoricalData = await this.apiService.GetHistoricalData(_id)
        return HistoricalData
    }

}