import { host } from '../Connnection';

async function loginUser(credentials) {
    return fetch(host+'/api/Account/Login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default loginUser;