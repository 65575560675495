import getProductStock from '../../Services/productBundles/getProductInfo';


export async function searchHints(query) {
    let getProductInfo = await getProductStock(query);
    var list = [];

    let hints = getProductInfo.items
    hints.forEach(hints => {
        let row = {};
        row.name = hints.name
        row.symbol = hints.symbol
        row.stock = hints.stock
        list.push(row);
    });

    return list;
}
