/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { searchHints } from '../../data/productBundles/getProductInfoData';
import { QueryValue } from './asyncNamesList';



function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const AsyncListSymbols = (params) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState((params.row.symbol) ? [{ 'symbol': params.row.symbol, 'stock': params.row.stock }] : '');
  const [stock, setStock] = React.useState();

  const [currentValue, setCurrentValue] = React.useState((params.row.symbol) ? { 'symbol': params.row.symbol, 'stock': params.row.stock } : '');
  const [stockStatus, setStockStatus] = React.useState((params.row.stock) ? { 'stock': params.row.stock } : '');

  const [searchValue, setSearchValue] = React.useState((params.row.symbol) ? params.row.symbol : '');
  const loading = open && options.length === 0;
  params.row.symbol = (currentValue) ? currentValue.symbol : '';
  params.row.stock = (currentValue) ? currentValue.stock : '';

  React.useEffect(() => {
    if (open != true) {
      if (options instanceof Array && options) {
          const matchedOption = options.find((option) => option.symbol === params.row.symbol)
          if (matchedOption instanceof Object) {
            setCurrentValue({ 'symbol': params.row.symbol, 'stock': matchedOption.stock })
          }
      
      }
    }
  }, [options]);


  React.useEffect(() => {

    if (searchValue !== '') {
      searchHints(searchValue).then((data) => {
        setOptions([...data])
        setStock(data)
      });
    }

  }, [searchValue]);


  React.useEffect(() => {
    QueryValue(currentValue)
  }, [currentValue])

  let symoblActive;
  if (currentValue) {
    symoblActive = currentValue;
  }



  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        if (searchValue !== '') {
          searchHints(searchValue).then((data) => {
            setOptions([...data])
          });
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  const handleChangeSymbol = (event) => {
    setSearchValue(event.target.value);
    setCurrentValue({ 'symbol': event.target.value });
  };

  const handleCurrentValue = (event, value) => {
    setCurrentValue(value);

  }

  return (
    <>
      {/* <QueryValue query={(currentValue) && currentValue} /> */}
      <Autocomplete
        id="select-symbol"
        sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        freeSolo
        isOptionEqualToValue={(option, value) => (option) ? option.symbol : 0 === (value) ? value.symbol : 0}
        getOptionLabel={(option) => option.symbol}
        options={options}
        fullWidth
        value={symoblActive}
        onChange={handleCurrentValue}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ borderBottom: '0' }}
            placeholder="Wprowadź symbol"
            variant="standard"

            // value={searchValue}
            onChange={handleChangeSymbol}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default AsyncListSymbols