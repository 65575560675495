import { host, token } from "../../../../Services/Connnection";
import {
    LastViewModel,
    ProductsList,
    ProductsPostParams,
    WebsiteDictionary,
} from "../../Models";
import { IProductHunterApiService } from "./IProductHunterApiService";

export class ProductHunterApiService implements IProductHunterApiService {

    async GetLastView(): Promise<LastViewModel> {
        let url = new URL(`${host}/api/LastView`);
        const response = await fetch(url.href, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return await response.json()
    }

    async PutLastView(websiteId: number): Promise<void> {
        let url = new URL(`${host}/api/LastView`);
        await fetch(url.href, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(websiteId)
        });
    }

    async GetProducts(_params: ProductsPostParams): Promise<ProductsList> {
        let url = new URL(`${host}/api/OnlineProduct/GetProducts`);
        const response = await fetch(url.href, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_params)
        });
        return await response.json()
    }

    async GetWebsiteDictionary(): Promise<WebsiteDictionary> {
        let url = new URL(`${host}/api/Websites`);
        const response = await fetch(url.href, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return await response.json()
    }

    async SetProcessedState(SelectedId: number[]): Promise<void> {
        let url = new URL(`${host}/api/OnlineProduct/SetProcessedState`);
        await fetch(url.href, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(SelectedId)
        });
    }

    async GetHistoricalData(_id: string): Promise<ProductsList> {
        let url = new URL(`${host}/api/OnlineProduct/GetProductHistory/${_id}`);

        const response = await fetch(url.href, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return await response.json()
    }

    async ExportOnlineProducts(_params: ProductsPostParams): Promise<string> {
        let url = new URL(`${host}/api/OnlineProduct/ExportProducts`);
        const response = await fetch(url.href, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'content-disposition': 'attachment',
            },
            body: JSON.stringify(_params)
        });
        return await response.text()
    }

}

