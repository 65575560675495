import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';

export default function QtyField(params) {
    const [value, setValue] = React.useState((params.row.quantity) ? params.row.quantity : 0);
    const handleChange = (e) => {
        if (e.target.value != undefined) {
            setValue(e.target.value)
            params.row.quantity = e.target.value
        }
    }

    
    return (
        <TextField
            variant="standard"
            type="Number"
            fullWidth
            id="bundle-qty-field"
            value={value}
            onChange={handleChange}

        />
    );
}



