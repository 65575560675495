import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import List from '@mui/material/List';

export const FireNav = styled(List)({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
    '& .MuiPaper-root': {
        background: "#1e1f21"
    },
    ".MuiTypography-body1": {
        fontSize: ".8rem",
        margin: 0,

        lineHeight: '1.5',
        letterSpacing: '0.00938em'

    },
    '& .MuiTypography-root': {
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif',
        fontWeight: 400,
        // color: '#e3e3e3'
    }
});


// export const item = {
//     py: '2px',
//     px: 3,
//     color: 'rgba(255, 255, 255, 0.7)',
//     '&:hover, &:focus': {
//         bgcolor: 'rgba(255, 255, 255, 0.08)',
//     },
// };

// export const itemCategory = {
//     boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
//     py: 1.5,
//     px: 3,
// };

export default FireNav;


