import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import {
    Search,
    SearchIconWrapper,
    StyledInputBase
} from '../../helpers/styles/WebsiteSearchBarStyle'

export default function MenuWebsitesSearchBar({ handleSearch }: { handleSearch: (event: any) => void }) {
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Wyszukaj"
                inputProps={{ 'aria-label': 'Wyszukaj' }}
                onChange={handleSearch}
            />
        </Search>
    )
}
