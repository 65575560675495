import * as React from 'react';
import TextField from '@mui/material/TextField';
import { searchHints } from '../../data/productBundles/getProductInfoData';

let currentSymbolVal;
export function QueryValue(value) {
    if (value != null || value !== undefined) {
        currentSymbolVal(value?.symbol)
    }
}

const AsyncSuggestNames = (params) => {
    const [options, setOptions] = React.useState((params.row.name) ? { 'name': params.row.name } : '');
    const [currentValue, setCurrentValue] = React.useState((params.row.name) ? { 'name': params.row.name } : '');
    const [currentSymbolState, setCurrentSymbolState] = React.useState('');
    currentSymbolVal = setCurrentSymbolState
    if (currentValue !== '' || currentValue !== undefined) {
        params.row.name = (currentValue) ? currentValue.name : 0;
    }

    let hasValue;
    if (currentValue) hasValue = currentValue

    React.useEffect(() => {

        if (currentSymbolState) {
            if (currentSymbolState !== undefined) {
                searchHints(currentSymbolState).then((data) => {
                    setOptions([...data])
                    if (options) {
                        if (options[0]) {
                            if (options[0]['symbol'] === params.row.symbol) {
                                params.row.symbol = (currentSymbolState !== '' || currentSymbolState !== undefined) ? currentSymbolState : 0;
                            }
                        }
                    }

                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSymbolState]);

    React.useEffect(() => {
        
       
            const matchedOption = options instanceof Array && options && options.find(option => option.symbol === params.row.symbol)
            const result = matchedOption ? matchedOption : params.row.symbol;
            
            if (matchedOption) {
                setCurrentValue({ 'name': matchedOption.name })
            } else {
                setCurrentValue({ 'name': "Nie wykryto nazwy produktu lub nie znaleziono produktu" })
            }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    const handleCurrentValue = (event, value) => {
        setCurrentValue({
            ...currentValue,
            [event.target.name]: event.target.value
        });


    }

    return (
        <TextField
            sx={{ borderBottom: '0' }}
            fullWidth
            placeholder="Wprowadź nazwę"
            variant="standard"
            name="name"
            value={(hasValue) ? hasValue.name : ''}
            onChange={handleCurrentValue}
        />

    );
};

export default AsyncSuggestNames