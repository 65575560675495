

const accessObject = JSON.parse(window.localStorage.getItem('accessStorage'));

export const userID = accessObject?.id
export const token = (accessObject) ? accessObject.accessToken : '';
export const permission = (accessObject) ? accessObject.role : '';

const currentHost = window.location.hostname
// export const host = (currentHost == 'localhost') ? `https://pro.isprzet.net:1001` : "https://apipro.grupakrawczyk.pl"
export const host = "https://apipro.grupakrawczyk.pl"

export const bundleHost = "https://192.168.10.12:449" 