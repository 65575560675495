import React from 'react'
import { WebsiteDictionary, WebsitesDictionaryList } from '../../../Pages/ProductHunter/Models'
import { ProductHunterDataService } from '../../../Pages/ProductHunter/Services/DataServices/DataService'
import { MenuWebsitesList } from './MenuWebsitesList'
import { NavigateFunction, useNavigate } from 'react-router';
import MenuWebsitesSearchBar from './MenuWebsitesSearchBar'

export default function MenuWebsitesIndex(): JSX.Element {
  let [websiteList, setWebsiteList] = React.useState<WebsiteDictionary>(
    {
      'filtered': [],
      'unfiltered': []
    }
  )

  const navigate: NavigateFunction = useNavigate();
  let DataService: any = React.useMemo(() => {
    return new ProductHunterDataService()
  }, [])

  const handleClick = (id: number,): void => {
    navigate(`/hunter/list/${id}`);
    DataService.putCurrentDate();
    changeCurrentVisited(id);
  }

  const handleSearch = (ev: { target: { value: string; }; }) => {
    let value = ev.target.value.toLowerCase()
    let filteredList = websiteList?.unfiltered?.filter(e => {
      let arrayWName = e.websiteName.toLowerCase()
      return arrayWName.includes(value)
    })
    setWebsiteList(
      {
        'filtered': filteredList,
        'unfiltered': websiteList?.unfiltered
      }
    )
  }

  const changeCurrentVisited = (id: number): WebsitesDictionaryList => {
    const thisWebsite: WebsitesDictionaryList | any = websiteList?.filtered?.find(e => e.id === id)
    thisWebsite.visited = true
    return thisWebsite
  }

  React.useEffect(() => {
    let active: boolean = true;
    (async () => {
      let ApiWebsiteList = await DataService.WebsiteDictionaryObject()
      if (!active) {
        return
      }
      setWebsiteList(
        {
          'filtered': ApiWebsiteList,
          'unfiltered': ApiWebsiteList
        }
      )
    })()
    return () => {
      active = false
    }
  }, [DataService])

  return (
    <>
      <MenuWebsitesSearchBar handleSearch={handleSearch} />
      {websiteList?.filtered?.map((d: WebsitesDictionaryList) => (
        <MenuWebsitesList key={d.id} data={d} nav={handleClick} />
      ))
      }
    </>
  )
}
