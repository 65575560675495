
import { bundleHost } from '../Connnection';

export default async function getProductStock(query) {

  try {
    let res = await fetch(`${bundleHost}/api/app/product-stock?SymbolFilter=${encodeURIComponent(query)}&UsePagination=true&MaxResultCount=20&MergeIntegrations=true`, {
      method: 'GET',
    })
    return await res.json();
  } catch (error) {
    console.log(error);
  }
}