import React from 'react'
import { Avatar, Box, Typography, Skeleton } from '@mui/material';


export function SkeletonBoxes() {
    return (
        <Box sx={{my: 2}}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mx: 2 }}>
                <Box sx={{ width: '100%', mt: -3}}>
                    <Skeleton width="80%">
                        <Typography>.</Typography>
                    </Skeleton>
                </Box>
                <Box sx={{ mx: 1 }}>
                    <Skeleton width={56} height={56} variant="circular">
                        <Avatar />
                    </Skeleton>
                </Box>
            </Box>
            <Box sx={{ width: '30%', mx: 2, mt: -3}}>
                <Skeleton width="100%" height={50} >
                    <Typography>.</Typography>
                </Skeleton>
            </Box>
            <Box sx={{ width: '30%', mx: 2, mt:1, display: 'flex'}}>
                <Skeleton width="100%"  >
                    <Typography>.</Typography>
                </Skeleton>
            </Box>
        </Box>
    )
}
