import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import { IconButton, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Toolbar from '@mui/material/Toolbar';
import ProfileMenu from './AccountPopover';
import { useNavigate } from "react-router-dom";


function Header(props) {
  const { onDrawerToggle } = props;
  const navigate = useNavigate();

  return (
    <AppBar
      component="div"
      color="lightColors"
      position="static"
      sx={{
        zIndex: 999,
        position: 'fixed',
        boxShadow: '2px 2px 8px rgba(0,0,0,0.1)'
      }}
    >
      <Toolbar className='toolbar-width'>
        
          <Grid sx={{
            paddingTop: 1,
            paddingBottom: 1,
            display: {
              sm: 'none',
              xs: 'block'
            }
          }}
            item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Grid>

          <Grid sx={{ paddingTop: 2, paddingBottom: 2 }} container alignItems="center" spacing={1}>
            <Grid item xs>
              <Button
                color="primary"
                size="small"
                sx={{ mr: 2, backgroundColor: '#cfcfcf5c' }}
                onClick={() => navigate(-1)}>
                <ArrowBackIosNewIcon sx={{ fontSize: '1.2rem' }} />
              </Button>
            </Grid>
          </Grid>

          <Grid item >
            <ProfileMenu />
          </Grid>
       
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;